<template>

	<div class="section offers" v-if="contentDataSet.data !== null && contentDataSet.data.length>0">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<h5 class="post-heading mb-4">What do we offer?</h5>
				</div>
				<div class="scroll-container">
					<angebot-card v-for="(item, index) in contentDataSet.data" :key="index" :item="item" class="angebot-card mr-4"></angebot-card>
				</div>
			</div>
			<pagination class="pagination-style" v-if="contentDataSet.total>4" :page-count="contentDataSet.meta.last_page" :click-handler="changePage" containerClass="pagination" prev-text="&laquo" next-text="&raquo"></pagination>
			</div>
		</div>

	</template>